import React from "react";
import { Route, Redirect } from "react-router-dom";
import { settings } from '../config/theme';

export default function AuthenticatedRoute({ component: C, appProps, ...rest }) {
  if(settings.login){
    return (
      <Route
        {...rest}
        render={props =>
          appProps.isAuthenticated
            ? <C {...props} {...appProps} />
            : <Redirect
                to={`/login?redirect=${props.location.pathname}${props.location
                  .search}`}
              />}
      />
    );
  }
  else {
    return (
      <Route
        {...rest}
        render={props => <C {...props} {...appProps} />}
      />
    );
  }
}