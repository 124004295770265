import React, { useState }  from "react";
import styled from 'styled-components';
import WidgetFactory from './WidgetFactory';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import { colorScheme } from '../../config/theme';

const TextDiv = styled.div`
  margin: 20px 0px 20px 0px;
`
const HeaderStyled = styled.h2`
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  padding-top: 30px;
  color: ${colorScheme.headerText};
`

export default function TextWidget(props) {

  if(props.display === 'content') {
    return (
      <div>
        <TextDiv dangerouslySetInnerHTML={{__html: props.widget.text}} />
      </div>
    );
  }

  return (
    <>
    </>
  );
}