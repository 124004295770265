import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import styled from 'styled-components';

const GlyphiconStyled = styled(Glyphicon)`
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;

  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }
`

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button
      className={`${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <GlyphiconStyled glyph="refresh" />}
      {props.children}
    </Button>
  );
}