import {css} from 'styled-components';

export const breakpoints = {
    xs: 650,
    sm: 980
}

export const cssMediaQuery = {
    phone: `@media (max-width: ${breakpoints.xs}px)`,
    tablet: `@media (min-width: ${breakpoints.xs + 1}px) and (max-width: ${breakpoints.sm}px)`,
    desktop: `@media (min-width: ${breakpoints.sm + 1}px)`,
    tabletAndPhone: `@media (max-width: ${breakpoints.sm}px)`,
    tabletAndDesktop: `@media (min-width: ${breakpoints.xs + 1}px)`
}
