
/*var courseMMM = {
  title: "My Money Mechanics",
  type: "section",
  content: [
    {
      type: "section",
      title: "Principle 1: Take Control of Your Finances, Know WHY You Spend",
      key: "0000",
      content: [        
      ]
    },
    {
      type: "page",
      title: "Intro",
      key: "0001",
      next: "0005",
      content: [
        {
          type: "video",
          key: "0004",
          location: "MMM_Principle_01_Intro.mp4",
          thumbnail: "MMM_Principle_01_Intro.jpg"
        }
      ]
    },
    {
      type: "page",
      title: 'Content',
      key: "0005",
      previous: "0001",
      next: "0009",
      content: [
        {
          type: "video",
          key: "0007",
          location: "MMM_Principle_01_Content.mp4",
          thumbnail: "MMM_Principle_01_Content.jpg"
        }
      ]
    },
    {
      type: "page",
      title: 'Outro',
      key: "0009",
      previous: "0005",
      next: "3009",
      content: [
        {
          type: "video",
          key: "0007",
          location: "MMM_Principle_01_Outro.mp4",
          thumbnail: "MMM_Principle_01_Outro.jpg"
        }
      ]
    },
    {
      type: "section",
      title: "Principle 2: To Control It, You Have to Track It",
      key: "0012",
      content: [
      ]
    },
    {
      type: "page",
      title: 'Intro',
      key: "3009",
      previous: "0009",
      next: "0311",
      content: [
        {
          type: "video",
          key: "0010",
          location: "MMM_Principle_01_Intro.mp4",
          thumbnail: "MMM_Principle_01_Intro.jpg"
        }
      ]
    },
    {
      type: "page",
      title: 'Content',
      key: "0311",
      previous: "3009",
      next: "0312",
      content: [
        {
          type: "video",
          key: "0010",
          location: "MMM_Principle_01_Content.mp4",
          thumbnail: "MMM_Principle_01_Content.jpg"
        }
      ]
    },
    {
      type: "page",
      title: 'Outro',
      key: "0312",
      previous: "0311",
      next: "1002",
      content: [
        {
          type: "video",
          key: "0010",
          location: "MMM_Principle_01_Outro.mp4",
          thumbnail: "MMM_Principle_01_Outro.jpg"
        }
      ]
    },
    {
      type: "section",
      title: 'Principle 3: "Spend" Your Surplus in Three Ways',
      key: "1001",
      content: [
        
      ]
    },
    {
      type: "page",
      title: 'Intro',
      key: "1002",
      previous: "0312",
      next: "1005",
      content: [
        {
          type: "video",
          key: "1004",
          location: "MMM_Principle_01_Intro.mp4",
          thumbnail: "MMM_Principle_01_Intro.jpg"
        }
      ]
    },
    {
      type: "page",
      title: 'Content',
      key: "1005",
      previous: "1002",
      next: "1009",
      content: [
        {
          type: "video",
          key: "1007",
          location: "MMM_Principle_01_Content.mp4",
          thumbnail: "MMM_Principle_01_Content.jpg"
        }
      ]
    },
    {
      type: "page",
      title: 'Outro',
      key: "1009",
      previous: "1005",
      content: [
        {
          type: "video",
          key: "1007",
          location: "MMM_Principle_01_Outro.mp4",
          thumbnail: "MMM_Principle_01_Outro.jpg"
        }
      ]
    }        
  ]

};
*/

var course = {
  title: "Triduum Retreat",
  type: "section",
  content: [
    {
      type: "section",
      title: "Holy Thursday",
      key: "0000",
      content: [        
      ]
    },
    {
      type: "page",
      title: "Gospel Meditation",
      key: "0001",
      next: "0005",
      content: [
        {
          type: "youtube",
          key: "0004",
          location: "https://youtu.be/0aO6nKTM_gg"
        },
        {
          type: "text",
          key: "1111",
          text: "This meditation is brought to you by José at Hodós International Ministries. You can learn more about Hodós International Ministries and support their ministry by visiting <a href='https://hodosinternationalministries.org/' target='_blank'>hodosinternationalministries.org</a>."
        }
      ]
    },
    {
      type: "page",
      title: 'Love vs Fear during COVID-19',
      key: "0005",
      previous: "0001",
      next: "3009",
      content: [
        {
          type: "youtube",
          key: "0007",
          location: "https://www.youtube.com/watch?v=44k8LyBy27s"
        },
        {
          type: "text",
          key: "1111",
          text: "This talk is given by Katie, the leader of <a href='https://catholicretreats.net/' target='_blank'>CatholicRetreats.net</a>. Check out the new website that she mentions in her talk - <a href='https://catholicpandemic.com' target='_blank'>CatholicPandemic.com</a> - where you can find online Catholic resources while you're cooped up at home!"
        }
      ]
    },
    {
      type: "section",
      title: "Good Friday",
      key: "0012",
      content: [
      ]
    },
    {
      type: "page",
      title: 'Gospel Meditation',
      key: "3009",
      previous: "0005",
      next: "0311",
      content: [
        {
          type: "youtube",
          key: "0010",
          location: "https://www.youtube.com/watch?v=2XAHFBRecwk"
        },
        {
          type: "text",
          key: "1111",
          text: "This talk is brought to you by Tirienne, founder of <a href='https://www.youtube.com/channel/UC0vO5uSFVOHNCLXBKkl__dw' target='_blank'>CathoCurious</a>.  Subscribe to her channel to get inspiring and fun content in your YouTube newsfeed every week!"
        }
      ]
    },
    {
      type: "page",
      title: 'Being Still during COVID-19',
      key: "0311",
      previous: "3009",
      next: "1002",
      content: [
        {
          type: "youtube",
          key: "0010",
          location: "https://www.youtube.com/watch?v=OAyzfApf4nI"
        },
        {
          type: "text",
          key: "1111",
          text: "This talk is brought to you by Tirienne, founder of <a href='https://www.youtube.com/channel/UC0vO5uSFVOHNCLXBKkl__dw' target='_blank'>CathoCurious</a>.  Subscribe to her channel to get inspiring and fun content in your YouTube newsfeed every week!"
        }
      ]
    },
    {
      type: "section",
      title: 'Holy Saturday',
      key: "1001",
      content: [
        
      ]
    },
    {
      type: "page",
      title: 'Gospel Meditation',
      key: "1002",
      previous: "0311",
      next: "1009",
      content: [
        {
          type: "youtube",
          key: "1004",
          location: "https://youtu.be/OSe5-R3Juec"
        },
        {
          type: "text",
          key: "1111",
          text: "This meditation is brought to you by José at Hodós International Ministries. You can learn more about Hodós International Ministries and support their ministry by visiting <a href='https://hodosinternationalministries.org/' target='_blank'>hodosinternationalministries.org</a>."
        }
      ]
    },
    {
      type: "page",
      title: 'Practical Tips for Parents',
      key: "1009",
      previous: "1002",
      content: [
        {
          type: "youtube",
          key: "1007",
          location: "https://www.youtube.com/watch?v=tnyxjh_pEUI"
        },
        {
          type: "text",
          key: "1111",
          text: "This talk is brought to you by Tirienne, founder of <a href='https://www.youtube.com/channel/UC0vO5uSFVOHNCLXBKkl__dw' target='_blank'>CathoCurious</a>.  Subscribe to her channel to get inspiring and fun content in your YouTube newsfeed every week!"
        }
      ]
    }
  ]

};

export default course;