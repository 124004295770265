import React, { useState, useEffect }  from "react";
import { API, Storage } from "aws-amplify";
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import WidgetFactory from './WidgetFactory';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom'

const PlayerWrapperSytled = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const ReactPlayerStyled = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const MarginDiv = styled.div`
  margin: 30px;
`

// To get png of video: https://www.raymond.cc/blog/extract-video-frames-to-images-using-vlc-media-player/
export default function VideoWidget(props) {
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoJpg, setVideoJpg] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        const videoUrl = await Storage.get(props.widget.location);
        const videoJpg = await Storage.get(props.widget.thumbnail);
        setVideoUrl(videoUrl);
        setVideoJpg(videoJpg);
      } catch (e) {
        console.log(e);
      }
    }

    onLoad();
  }, [props.widget.location]);

  if(props.display === 'content') {
    return (
      <>
      {videoUrl && videoJpg && 
          (<>
            <MarginDiv></MarginDiv>
            <PlayerWrapperSytled>
              <ReactPlayerStyled
                className='react-player'
                width='100%'
                height='100%'
                url={videoUrl}
                light={videoJpg}
                controls={true}
                playing={true}
              />
            </PlayerWrapperSytled>
            <MarginDiv></MarginDiv>
          </>)
      }
      </>
    );
  }

  return (
    <>
    </>
  );
}