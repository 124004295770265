import React, { useState, useEffect }  from "react";
import { Nav, Navbar, NavItem, Button, Grid, Row, Col} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import course from '../data/course';
import SideNav from "./SideNav";
import WidgetFactory from '../components/widgets/WidgetFactory';
import { breakpoints, cssMediaQuery } from '../config/mediaqueries';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom'

const LanderDivStyled = styled.div`
  text-align: left;
`

const HeaderStyled = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  padding-top: 30px;
`

const PlayerWrapperSytled = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
`

const ReactPlayerStyled = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const LeftNavDiv = styled.div`

`

export default function Course({match}) {
  const [showMore, setShowMore] = useState(false);
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    handleResize();
  }, []);

  function showMoreButtonClicked(e) {
    e.preventDefault();
    setShowMore(!showMore);
  }

  let displayPage = course.content.filter(widget => {
    return widget.type === 'page';
  })[0];

  if(match.params.pageid) {
    displayPage = course.content.filter(widget => {
      return widget.key === match.params.pageid;
    })[0];
  };

	function handleResize () {
    setWindowSize(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Grid>
      <Row className="show-grid">
      {windowSize <= breakpoints.sm ? 
        <>
          <Col xs={12} md={12}>
            <LanderDivStyled> 
              <WidgetFactory widget={displayPage} key={displayPage.key} url={`/course`} display='content' windowSize={windowSize}/>
            </LanderDivStyled>
          </Col>
        </>
        : 
        <>
          <Col xs={2} md={2}>
            <SideNav></SideNav>
          </Col>
          <Col xs={8} md={8}>
            <LanderDivStyled>            
              <WidgetFactory widget={displayPage} key={displayPage.key} url={`/course`} display='content'/>
            </LanderDivStyled>
          </Col>
        </>
      }
      </Row>
    </Grid>
  );
}