export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY: "pk_test_7Vwo5loH2NJA08e5r2NK0juY00jvEiDrrY",
  s3: {
    REGION: "us-east-1",
    BUCKET: "dev.dobbins"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://ac2bv9m25m.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_BpYuMNITA",
    APP_CLIENT_ID: "47e11v2urbtv0elfmmdccce29h",
    IDENTITY_POOL_ID: "us-west-2:f1dd0ee7-e508-46ec-8ea4-30d66bb969f9"
  }
};