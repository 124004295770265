import React, { useState }  from "react";
import SideNav from '../../containers/SideNav'
import { Nav, Navbar, NavItem, Button, Grid, Row, Col, Modal} from "react-bootstrap";
import styled from 'styled-components';
import WidgetFactory from './WidgetFactory';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints, cssMediaQuery } from '../../config/mediaqueries';
import { colorScheme } from '../../config/theme';

const PageSidebar = styled.div`
  // margin-left:10px;
  // margin-top:10px;
  color: ${colorScheme.headerText};
  
`
const HeaderStyled = styled.h2`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  padding-top: 30px;
  color: ${colorScheme.headerText};
`

const VideoImage = styled(FontAwesomeIcon)`
  margin-right:8px;
`
const ArrowImage = styled(FontAwesomeIcon)`
  margin-right:6px;
  margin-left:6px;
`

const ButtonStyled = styled(Button)`
  width: 100%;
  border-radius: 0px;
  padding: 10px;
  color: ${colorScheme.headerText};
`

const ButtonStyled2 = styled(Button)`
  width: 90%;
  border-radius: 3px;
  padding: 10px;
  text-align: left;
  margin-left:5px;
  border: 0px;
  font-size: 16px;
  color: ${colorScheme.headerText};
`

const ButtonColStyled = styled(Col)`
  padding:0px;
  margin:0px;
`

const ModalStyled = styled(Modal)`
`

export default function PageWidget(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if(props.display === 'content') {
    return (
      <>
      <Row className="show-grid">
      {props.windowSize <= breakpoints.sm ? 
          <>
            <ButtonColStyled xs={2} md={2}>
              <ButtonStyled onClick={handleShow}><ArrowImage icon="list-ul"/></ButtonStyled>
            </ButtonColStyled>
            <ButtonColStyled xs={5} md={5}>
              {props.widget.previous && <Link to={`${props.widget.previous}`}><ButtonStyled><ArrowImage icon="arrow-left"/>Previoius</ButtonStyled></Link>}
            </ButtonColStyled>
            <ButtonColStyled xs={5} md={5}>
              {props.widget.next && <Link to={`${props.widget.next}`}><ButtonStyled>Next<ArrowImage icon="arrow-right" /></ButtonStyled></Link>}
            </ButtonColStyled>
            <div>
              <ModalStyled show={show} onHide={handleClose}>
                <Modal.Body>
                  <SideNav closeModal={handleClose}></SideNav>
                </Modal.Body>
              </ModalStyled>
            </div>
          </>
        : 
          <>
            <ButtonColStyled xs={6} md={6}>
              {props.widget.previous && <Link to={`${props.widget.previous}`}><ButtonStyled><ArrowImage icon="arrow-left"/>Previoius</ButtonStyled></Link>}
            </ButtonColStyled>
            <ButtonColStyled xs={6} md={6}>
              {props.widget.next && <Link to={`${props.widget.next}`}><ButtonStyled>Next<ArrowImage icon="arrow-right" /></ButtonStyled></Link>}
            </ButtonColStyled>
          </>
        }
      </Row>
      <HeaderStyled>{props.widget.title}</HeaderStyled>
      {props.widget.content.map(widget => (
        <WidgetFactory widget={widget} key={widget.key} url={`${props.url}/${props.widget.key}`} display='content'/>
     ))}
      </>
    );
  }

  return (
    <>
    <Row className="show-grid">
        <ButtonColStyled xs={12} md={12}>
          <Link to={`${props.url}/${props.widget.key}`} onClick={props.closeModal}>
            {/* <PageSidebar><VideoImage icon={['fab', 'youtube']} />{props.widget.title}</PageSidebar> */}
            {/* <ArrowImage icon="check-circle"></ArrowImage> */}
            <PageSidebar><ButtonStyled2><VideoImage icon={['fab', 'youtube']} />{props.widget.title}</ButtonStyled2></PageSidebar>
          </Link>
        </ButtonColStyled>
      </Row>
      {props.widget.content.map(widget => (
        <WidgetFactory widget={widget} key={widget.key} url={`${props.url}/${props.widget.key}`}/>
     ))}
    </>
    
  );
}