import React, { useState } from "react";
import { API } from "aws-amplify";
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingForm from "../components/BillingForm";
import config from "../config";
import styled from 'styled-components';


const SettingsDivStyled = styled.div`
  @media all and (min-width: 480px) {
    padding: 60px 0;
  }
`

const LanderDivStyled = styled.div`
  padding: 30px 0;
  text-align: center;
`

const HeaderStyled = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
`

const ContentStyled = styled.p`

`

export default function Settings(props) {
  const [isLoading, setIsLoading] = useState(false);

  function billUser(details) {
    return API.post("mmm", "/billing", {
      body: details
    });
  }

  async function handleFormSubmit(storage, { token, error }) {
    if (error) {
      alert(error);
      return;
    }
  
    setIsLoading(true);
  
    try {
      await billUser({
        storage,
        source: token.id
      });
  
      alert("Your card has been charged successfully!");
      props.history.push("/course");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }
  
  return (
    <SettingsDivStyled>
      <LanderDivStyled>
        <HeaderStyled>Collecting Payments</HeaderStyled>
        <ContentStyled>The below form is connected to a Stripe test account. </ContentStyled>
        <ContentStyled>To test it, put in any name, date, CVC, Zip, and this card number: 4242 4242 4242 4242</ContentStyled>
      </LanderDivStyled>
      <StripeProvider apiKey={config.STRIPE_KEY}>
        <Elements>
          <BillingForm
            isLoading={isLoading}
            onSubmit={handleFormSubmit}
          />
        </Elements>
      </StripeProvider>
    </SettingsDivStyled>
  );
}