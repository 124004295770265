import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import styled from 'styled-components';
import Routes from "./Routes";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faArrowRight, faArrowLeft, faCircle, faCheckCircle, faListUl} from '@fortawesome/free-solid-svg-icons';
import { settings, colorScheme } from './config/theme.js';

library.add(faCheckSquare ,faCoffee, faArrowRight, faArrowLeft, faCircle, faCheckCircle, fab, faListUl);

const AppStyled = styled.div`
  
`

const NavbarBrandStyled = styled(Navbar.Brand)`
  color: #FFFFFF;
  font-weight: bold;
`

const NavbarStyled = styled(Navbar)`
  color: #FFFFFF;
  border-radius: 0px;
  border-color: ${colorScheme.headerText};
  border: 5px;
  margin-bottom:0px;
`

const LinkBrandStyled = styled(Link)`
  color: #FFFFFF;
`

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [navExpanded, setNavExpanded] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      setNavExpanded(false);
    }
    catch(e) {
      if (e !== 'No current user') {
        // alert(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    toggleNavbar();
    userHasAuthenticated(false);
  
    props.history.push("/login");
  }

  const toggleNavbar = () => {
    setNavExpanded(!navExpanded);
  }


  if(settings.login){
      return (
        !isAuthenticating &&
        <AppStyled>
          <NavbarStyled fluid collapseOnSelect onToggle={toggleNavbar} expanded={navExpanded}>
            <Navbar.Header>
              <NavbarBrandStyled>
                <LinkBrandStyled to="/">My Money Mechanics</LinkBrandStyled>
              </NavbarBrandStyled>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
              {isAuthenticated
                ? <>
                    <LinkContainer to="/course/0001" onClick={toggleNavbar}>
                      <NavItem>Course</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/settings" onClick={toggleNavbar}>
                      <NavItem>Settings</NavItem>
                    </LinkContainer>
                    <NavItem onClick={handleLogout}>
                      Logout
                    </NavItem>
                  </>
                : <>
                    <LinkContainer to="/signup" onClick={toggleNavbar}>
                      <NavItem>Signup</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login" onClick={toggleNavbar}>
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </>
              }
              </Nav>
            </Navbar.Collapse>
          </NavbarStyled>
          <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
        </AppStyled>
      );
        }
        else{
          return (
            <AppStyled>
            <NavbarStyled fluid collapseOnSelect onToggle={toggleNavbar} expanded={navExpanded}>
              <Navbar.Header>
                <NavbarBrandStyled>
                  <LinkBrandStyled to="/" exact>Catholic Retreats</LinkBrandStyled>
                </NavbarBrandStyled>
                <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse>
                <Nav pullRight>
                  <>
                  <LinkContainer to="/home" onClick={toggleNavbar}>
                      <NavItem>Home</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/course/0001" onClick={toggleNavbar}>
                      <NavItem>Content</NavItem>
                    </LinkContainer>
                  </>
                </Nav>
              </Navbar.Collapse>
            </NavbarStyled>
            <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
          </AppStyled>
          );
        }
}

export default withRouter(App);
