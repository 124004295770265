import React from "react";
import styled from 'styled-components';
import { Nav, Navbar, NavItem, Button, Grid, Row, Col} from "react-bootstrap";
import { settings } from '../config/theme.js';
import SideNav from "./SideNav.js";
import { Content } from "react-bootstrap/lib/Tab";
import YouTubeWidget from '../components/widgets/YouTubeWidget'

const LanderDivStyled = styled.div`
  padding: 80px 0;
  text-align: center;
`

const HeaderStyled = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
`

const ContentStyled = styled.p`
  
`

const OutlineDivStyled = styled.div`
  margin: 3px 3px 40px 3px;
  padding: 3px;
  border: 5px;
  border-width: thin;
  border-color: #D3D3D3;
  border-style: solid;
  border-radius: 5px;
`


export default function Home() {
  // const [windowSize, setWindowSize] = useState(false);

  // useEffect(() => {
  //   handleResize();
  // }, []);

  // function handleResize () {
  //   setWindowSize(window.innerWidth);
  // };
  // window.addEventListener('resize', handleResize);

  if(settings.login){
    return (
      <div className="Home">
        <LanderDivStyled>
          <HeaderStyled>My Money Mechanics</HeaderStyled>
          <ContentStyled>Learn all about money from Will Street and Spencer Couch!</ContentStyled>
        </LanderDivStyled>
      </div>
    );
  }
  else {
    let videoProps = {display: Content, widget: { location: ''}};
    return (
      <div className="Home">
        <LanderDivStyled>
          <HeaderStyled>Triduum Retreat</HeaderStyled>
          <ContentStyled>
            Join us for an online Triduum retreat as we celebrate the coming of Easter!  <br/>
            This retreat is brought to you by Hodós International Ministries, CatholicRetreats.net, and CathoCurious.
          </ContentStyled>
          <div>
            <a href='https://hodosinternationalministries.org/' target='_blank'><img style={{height:'150px', margin:'10px'}} src="https://hodosinternationalministries.org/wp-content/uploads/2020/01/cropped-LogoText_Side_Color-e1579112942121-2048x906.png" /></a>
            <a href='https://catholicretreats.net/' target='_blank'><img style={{height:'60px', margin:'10px'}} src="https://catholicretreats.net/images/logo2-e59de272.png"/></a>
          </div>
          

          <>
            <Col xs={0} md={3}>   
            </Col>
            <Col xs={12} md={6}> 
              <YouTubeWidget widget={{location: "https://www.youtube.com/watch?v=18SNwMs_2Rg"}} display={'content'}></YouTubeWidget>    
            </Col>
            <Col xs={0} md={3}>          
            </Col>
          </>

          <>
            <Col xs={0} md={4}>          
            </Col>
            <Col xs={12} md={4}>  
              <OutlineDivStyled>

                <SideNav></SideNav>   
              </OutlineDivStyled>     
            </Col>
            <Col xs={0} md={4}>          
            </Col>
          </>
        </LanderDivStyled>
      </div>
    );
  }
}