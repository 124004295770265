import React, { useState }  from "react";
import { Nav, Navbar, NavItem, Button, Grid, Row, Col} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import course from '../data/course'
import WidgetFactory from '../components/widgets/WidgetFactory'


export default function SideNav(props) {
  return (
    <>
    {course.content.map(widget => (
        <WidgetFactory widget={widget} key={widget.key} url={`/course`} closeModal={props.closeModal}/>
     ))}
    </>
  );
}