import React, { useState }  from "react";
import WidgetFactory from './WidgetFactory';
import styled from 'styled-components';
import Course from '../../containers/Course';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';

const HeaderStyled = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  padding-top: 30px;
`

const SidebarTitleStyled = styled.h4`
  margin-top:30px;
  font-weight: bold;
`

export default function SectionWidget(props) {
  // console.log(props.match.url);
  if(props.display === 'content') {
    return (
      <>
      {/* <HeaderStyled>{props.widget.title}</HeaderStyled> */}
      {props.widget.content.map(widget => (
        <WidgetFactory widget={widget} key={widget.key} url={`${props.url}/${props.widget.key}`} display='content'/>
     ))}
      </>
    );
  }

  return (
    <>
      <SidebarTitleStyled>{props.widget.title}</SidebarTitleStyled>
      {props.widget.content.map(widget => (
        <WidgetFactory widget={widget} key={widget.key} url={`${props.url}/${props.widget.key}`}/>
     ))}
    </>

  );
}