import React, { useState }  from "react";
import SectionWidget from "./SectionWidget"
import PageWidget from "./PageWidget"
import TextWidget from "./TextWidget";
import VideoWidget from "./VideoWidget";
import YouTubeWidget from "./YouTubeWidget";

// https://dev.to/shadid12/react-js-with-factory-pattern-building-complex-ui-with-ease-1ojf
export default function WidgetFactory(props) {
  console.log(props);
  switch (props.widget.type) {
    case "section":
      return <SectionWidget widget={props.widget} url={props.url} display={props.display}/>;
    case "page":
      return <PageWidget widget={props.widget} url={props.url} display={props.display} windowSize={props.windowSize} closeModal={props.closeModal}/>;
    case "text":
      return <TextWidget widget={props.widget} url={props.url} display={props.display}/>;
    case "video":
      return <VideoWidget widget={props.widget} url={props.url} display={props.display}/>;
    case "youtube":
      return <YouTubeWidget widget={props.widget} url={props.url} display={props.display}/>;
    default:
      return <></>;
  }
}