
export const colorScheme = {
  main: "#4E5B30",
  headerText: "#000000"
}

export const theme = {
  logo: "lskdjf"
}

export const settings = {
  login: false
}

/* MMM
export const colorScheme = {
  main: "#4E5B30"
}
*/